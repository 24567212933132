import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
const ob = "AIzaSyDlaIBFSTXxJ3X";
const bo = "v_dP-eOTU652Zqj3QhKM"

const firebaseConfig = {
  apiKey: ob + bo,
  authDomain: "bluetrip-3ad1e.firebaseapp.com",
  projectId: "bluetrip-3ad1e",
  storageBucket: "bluetrip-3ad1e.appspot.com",
  messagingSenderId: "279385732925",
  appId: "1:279385732925:web:91c01df13db6afd0e73754",
};

initializeApp(firebaseConfig);