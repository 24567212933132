<template>
  <div id="main">
    <div class="wrapper">
      <div class="header-section">
        <img
          class="note note1"
          style=""
          :src="require('./assets/not-lugn.gif')"
        />
        <img
          class="note note2"
          style=""
          :src="require('./assets/not-lugn-2.gif')"
        />
        <img
          class="note note3"
          style=""
          :src="require('./assets/not-lugn-3.gif')"
        />
        <img
          class="roger-hero"
          style="width: 700px"
          :src="require('./assets/roger-without-notes.png')"
        />
        <h1 class="roger-header">Rogers restips</h1>
      </div>
      <!--<img style="width: 700px;" :src="require('./assets/header-final.png')" />-->
      <p class="ingress">
        Vet du vart du ska, men inte vad du ska göra? Ingen stress. Vår AI-reseledare Roger har varit överallt och delar gärna med sig av sina bästa hotspots, smultronställen och matupplevelser. Bon voyage!
      </p>
      <!--<p class="warning" v-if="!loading || !introduction">PS! Roger tar inget som helst ansvar för sitt tjöt*</p>-->
      <div class="prompt-form">
        <input
          v-model="userInput"
          @keyup.enter="submitPrompt"
          placeholder="Vart ska du åka?"
          maxlength="100"
        />
        <button @click="submitPrompt">Tipsa mig!</button>
      </div>
      <p class="warning">
        PS! Roger är mycket av en free-spirit och kan vara lite burdus ibland.
        Fråga på egen risk <span style="font-style: normal">😊</span>*
      </p>
    </div>

    <div class="response-wrapper" v-if="introductionUrlMessage && sightingsUrlMessage && !loading">
      <img
        class="roger-profile"
        style="width: 50px"
        :src="require('./assets/roger-profile.png')"
      />
      <div class="response" v-if="introductionUrlMessage">
        <div>{{ introductionUrlMessage }}</div>
      </div>
      <div class="response" v-if="sightingsUrlMessage">
        <div v-html="parseMarkdown(sightingsUrlMessage)"></div>
      </div>
    </div>

    <!-- Display Introduction -->
    <div class="response-wrapper" v-if="loading || suggestionsFood || outro">
      <img
        class="roger-profile"
        style="width: 50px"
        :src="require('./assets/roger-profile.png')"
      />
      <div class="response" v-if="firstMessage && !introduction">
        <div>{{ firstMessage }}</div>
      </div>

      <div class="response" v-if="introduction">
        <!--<img style="width: 50px;" :src="require('./assets/roger-profile.png')" />-->
        <div v-html="parseMarkdown(introduction)"></div>
      </div>

      <div class="response" v-if="secondMessage && !suggestionsSightings">
        <!--<img style="width: 50px;" :src="require('./assets/roger-profile.png')" />-->
        <div>{{ secondMessage }}</div>
      </div>

      <!-- Display Suggestions -->
      <div class="response" v-if="suggestionsSightings">
        <!--<img style="width: 50px;" :src="require('./assets/roger-profile.png')" />-->
        <div v-html="parseMarkdown(suggestionsSightings)"></div>
      </div>

      <div class="response" v-if="suggestionsFood && suggestionsSightings">
        <!--<img style="width: 50px;" :src="require('./assets/roger-profile.png')" />-->
        <div v-html="parseMarkdown(suggestionsFood)"></div>
      </div>

      <!-- Display Outro -->
      <div
        class="response"
        v-if="suggestionsSightings && suggestionsFood && outro"
      >
        <!--<img style="width: 50px;" :src="require('./assets/roger-profile.png')" />-->
        <div v-html="parseMarkdown(outro)"></div>
        <div>
          <p>
            Och du kompis, glöm inte att ta ut
            <a href="https://www.bankomat.se/karta/" target="_blank"
              >resevaluta</a
            >
            hos Bankomat innan du åker!
          </p>
        </div>
      </div>
      <div
        class="response-footer"
        v-if="suggestionsSightings && suggestionsFood && outro"
      >
        <!--<button class="small-button" @click="downloadPDF">
          Ladda ner din resplan
        </button>-->
        <button v-if="shareUrl" class="small-button" @click="copyToClipboard">
          Dela din resplan
        </button>
        <a href="https://www.bankomat.se/karta/" class="small-button link-button">
          Ta ut resevaluta
        </a>
          <div v-if="copied" class="notification-slide" :class="{'copied': copied}">
            {{ copiedMessage }}
          </div>
      </div>
      <!-- Display Loading Spinner -->
      <div class="response" style="display: flex" v-if="loading">
        <!--<img style="width: 50px; margin-right: 20px;" :src="require('./assets/roger-profile.png')" />-->
        <div class="chat-bubble">
          <div class="typing">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!searched" class="response-wrapper topSearches">
      <div v-if="randomPlaces.length">
        <h3>Andra har sökt på:</h3>
        <ol>
          <li v-for="place in randomPlaces" :key="place" @click="selectPlace(place)">{{ place }}</li>
        </ol>
      </div>
      <div v-if="randomPlaces.length">
        <h3>Populära resmål:</h3>
        <ol>
          <li v-for="place in popularPlaces" :key="place" @click="selectPlace(place)">{{ place }}</li>
        </ol>
      </div>
    </div>

    <div class="response-bottom">
      <span>Rogers restips, en AI-tjänst från Bankomat</span>
      <h5>Mer resevaluta för pengarna</h5>
    </div>

    <div class="footer" :class="{'is-done': suggestionsSightings && suggestionsFood && outro}">
      <div class="footer-links">
        <a href="https://www.bankomat.se/karta/" target="_blank">Hitta närmaste automat</a>
        <a href="https://open.spotify.com/track/7FPcaAmAkuIHeCuz93d4B7?si=e84923fd19d847a4">Lyssna på Bankomatlåten</a>
      </div>
      <div class="disclaimer">
        <p>
          *Visst känns Roger lite för bra för att vara sann? Det är han tyvärr
          också. Han är nämligen vår fiktiva favoritreseledare på Bluetrip. Och
          eftersom Rogers resetips är helt AI–genererade så kan man aldrig
          riktigt veta vad han svarar. Tipsen ska därför ses som inspiration.
          Roger är helt ovetandes om aktuella händelser som krig,
          miljökatastrofer, UD:s reserekommendationer och annat som kan påverka
          ditt resmål. Vi uppmanar dig att alltid läsa på noga innan du reser
          och följa aktuella rekommendationer. På bluetrip.se sätts eller sparas inga kakor.
          <strong>Trevlig resa önskar Bankomat!</strong>
        </p>
      </div>
      <div class="logo">
        <a href="https://www.bankomat.se/" target="_blank"><img
          style="width: 175px"
          :src="require('./assets/logo-bankomat.svg')"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import { httpsCallable, getFunctions } from 'firebase/functions';

//import { jsPDF } from "jspdf";
//import html2canvas from 'html2canvas';

export default {
  data() {
    return {
      userInput: "",
      loading: false,
      response: null,
      introduction: null,
      suggestionsSightings: null,
      suggestionsFood: null,
      outro: null,
      firstMessage: null,
      introductionUrlMessage: null,
      sightingsUrlMessage: null,
      secondMessage: null,
      copied: false,
      searched: false,
      copiedMessage: '',
      shareUrl: null,
      responseTemplates: [
        "Kul att du frågar! Låt mig slänga ihop en resplan!",
        "Jag ska slänga ihop en resplan till dig!",
        "Nu snackar vi! Ge mig några sekunder så slänger jag ihop en resplan till dig!",
        "Åh nu blev jag allt lite avis... Ge mig några sekunder så slänger jag ihop en resplan till dig! Gött mos!",
      ],
      md: new MarkdownIt(), // instantiate the markdown parser
      popularPlaces: ['New York', 'Paris', 'London'],
      places: [
        'Bali', 'Barcelona', 'Bermudatriangeln', 'Chicago', 'Antikens rom', 'Drömmarnas land',
        'Dublin', 'Edens lustgård', 'Edinburgh', 'Egypten', 'Elvis graceland', 'Enskede', 
        'Essex', 'Florida', 'Genarp', 'München', 'Nice', 'New York', 'Mordor', 'Nordpolen', 
        'Okinawa', 'Norge', 'Norrköping', 'Mumindalen', 'Ottawa', 'Pajala', 'Palma', 'Paris', 'Phuket', 
        'Portugal', 'Prag', 'Skatteverket', 'Sardinien', 'Sollefteå', 'Sollentuna', 'Solna', 
        'Spanien', 'Sydkorea', 'Södermalm', 'Teneriffa', 'Toaletten', 'Vadstena', 'Vikingatiden', 
        'Vintergatan', 'Zakynthos', 'Gotland', 'Valencia', 'Florida', 'Malaga', 'Mallorca', 
        'Malta', 'Manila', 'Minneapolis', 'London', 'Liverpool', 'Lettland', 'Korsika', 'Karlstad'
      ],
      randomPlaces: [],
      oapk: '',
    };
  },
  async created() {

  },
  mounted() {
    this.randomPlaces = this.selectRandomPlaces();
    const introduction = this.getQueryParam("introduction");
    const sightings = this.getQueryParam("sightings");
    // const place = this.getQueryParam("place");

    const searchId = this.getQueryParam("resa");
    const searchIdTwo = this.getQueryParam("search");
    if (searchId) {
      this.fetchResponseFromFirebase(searchId);
    }

    if (searchIdTwo) {
      this.fetchResponseFromFirebase(searchId);
    }

    // Handle these values - for example:
    if (introduction) {
      this.introductionUrlMessage = introduction;
    }

    if (sightings) {
      this.sightingsUrlMessage = sightings;
    }
  },
  methods: {
    selectRandomPlaces() {
      let shuffledPlaces = [...this.places].sort(() => 0.5 - Math.random());
      return shuffledPlaces.slice(0, 3); // Taking the top three items from the shuffled array
    },
    selectPlace(place) {
      this.userInput = place;
      this.submitPrompt();
    },
    async queryUserInputFromFirebase(userInput) {
      try {
        const functions = getFunctions(undefined, 'europe-west1');
        const queryFunction = httpsCallable(functions, 'queryUserInputFromFirebase');
        const result = await queryFunction({ userInput: userInput });

        return result.data || [];
      } catch (error) {
        console.error("Error querying user input:", error);
        return [];
      }
    },
      async copyToClipboard() {
        try {
          await navigator.clipboard.writeText(this.shareUrl);
          this.copied = true;
          this.copiedMessage = "Din resplan är kopierad";
          setTimeout(() => {
            this.copied = false;  // Hide the message after a delay
          }, 3000);  // 3 seconds delay
        } catch (err) {
          console.error('Failed to copy!', err);
        }
      },
      async saveResponseToFirebase() {
        try {
          const docData = {
            userInput: this.userInput,
            introduction: this.introduction,
            suggestionsSightings: this.suggestionsSightings,
            suggestionsFood: this.suggestionsFood,
            outro: this.outro
          };

          const functions = getFunctions(undefined, 'europe-west1');
          const saveFunction = httpsCallable(functions, 'saveResponseToFirebase');
          const result = await saveFunction(docData);

          // Get the document ID from the function response
          if (result.data) {
            let baseUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
            this.shareUrl = `${baseUrl}?resa=${result.data}`; // result.data contains the document ID
          }
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      },
      async incrementTotal() {
        try {
          const functions = getFunctions(undefined, 'europe-west1');
          const incrementFunction = httpsCallable(functions, 'incrementTotal');
          const result = await incrementFunction();

          if (result.data.success) {
            console.log();
          } else {
            console.error("Error incrementing total:", result.data.error);
          }
        } catch (error) {
          console.error("Error calling incrementTotal function:", error);
        }
      },
      async fetchResponseFromFirebase(id) {
        try {
          this.searched = true;

          // Call a Firebase Cloud Function to get the data
          const functions = getFunctions(undefined, 'europe-west1');
          const fetchFunction = httpsCallable(functions, 'fetchFromFirestore');
          const result = await fetchFunction({ docId: id });

          if (result.data.exists) {
            this.userInput = result.data.userInput;
            this.introduction = result.data.introduction;
            this.suggestionsSightings = result.data.suggestionsSightings;
            this.suggestionsFood = result.data.suggestionsFood;
            this.outro = result.data.outro;
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
    async submitPrompt() {
      this.searched = true;
      this.firstMessage = null;
      this.secondMessage = null;
      this.introduction = null;
      this.suggestionsSightings = null;
      this.suggestionsFood = null;
      this.outro = null;
      this.loading = true;

      this.scrollToBottom();
      this.incrementTotal();
            // Check if userInput already exists in Firestore
        const results = await this.queryUserInputFromFirebase(this.userInput);
  
        if (results.length > 0) {
          setTimeout(() => {
            this.firstMessage =
              this.responseTemplates[
                Math.floor(Math.random() * this.responseTemplates.length)
              ];
          }, 3000);
          // Use the first result
          const data = results[0];
        
          setTimeout(() => {
            this.introduction = data.introduction;
          }, 5000);
          setTimeout(() => {
            this.suggestionsSightings = data.suggestionsSightings;
          }, 9000);
          setTimeout(() => {
            this.suggestionsFood = data.suggestionsFood;
          }, 11000);
          setTimeout(() => {
            this.outro = data.outro;
            this.loading = false;
            let baseUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
            this.shareUrl = `${baseUrl}?resa=${data.id}`;
          }, 12000);
          return; // Exit early since we already have the data
        } else {
        setTimeout(() => {
          this.firstMessage =
            this.responseTemplates[
              Math.floor(Math.random() * this.responseTemplates.length)
            ];
        }, 3000);
        setTimeout(() => {
          if (!this.suggestions) {
            this.secondMessage = "Ge mig några sekunder till är du go'! ";
          }
        }, 23000);

        this.askTravelAssistant("introduction", this.userInput);
        //this.askTravelAssistant('suggestions', this.userInput);
        this.askTravelAssistant("suggestionsSightings", this.userInput);
        this.askTravelAssistant("suggestionsFood", this.userInput);
        this.askTravelAssistant("outro", this.userInput);

        // Check if all sections have been fetched to stop the loading
        this.checkLoadingStatus();
      }
    },

    getQueryParam(param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    },

    scrollToBottom() {
      window.scrollTo({
        top: document.documentElement.scrollHeight, // Scroll to bottom of the body
        behavior: "smooth", // Smooth scroll
      });
    },

    async checkLoadingStatus() {
      while (
        this.introduction === null ||
        this.suggestionsSightings === null ||
        this.suggestionsFood === null ||
        this.outro === null
      ) {
        await this.sleep(100); // check every 100ms
      }
      this.loading = false;
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    allSectionsPopulated() {
      return this.introduction && this.suggestionsSightings && this.suggestionsFood && this.outro;
    },
    async askTravelAssistant(section, userInput) {
      try {
        const functions = getFunctions(undefined, 'europe-west1');
        const askOpenAIFunction = httpsCallable(functions, 'askOpenAI');

        const response = await askOpenAIFunction({ section, userInput });

        const content = this.removeExtraSpaces(response.data);

        switch (section) {
          case "introduction":
            this.introduction = content;
            break;
          case "suggestionsSightings":
            this.suggestionsSightings = content;
            break;
          case "suggestionsFood":
            this.suggestionsFood = content;
            break;
          case "outro":
            this.outro = content;
            break;
        }

        if (this.allSectionsPopulated()) {
          this.saveResponseToFirebase();
        }

      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
    parseMarkdown(markdownString) {
      return this.md.render(markdownString);
    },

    removeExtraSpaces(content) {
      // Remove extra space after headline
      let cleanedContent = content.replace(/(## [^\n]+)\n+/g, '$1\n');
      
      // Remove extra spaces between list items
      cleanedContent = cleanedContent.replace(/(\d\.[^\n]+)\n+/g, '$1\n');

      // Remove multiple consecutive empty lines
      cleanedContent = cleanedContent.replace(/\n{3,}/g, '\n\n');

      return cleanedContent;
    }
  },
};
</script>
<style>
@import url("https://use.typekit.net/qmj7rjm.css");


body {
  background: #01a5b3;
  font-family: Helvetica, Arial, sans-serif;
  transition: all 0.3s ease-in-out;
}

h1.roger-header {
  font-family: "thirsty-script";
  font-size: 6em;
  color: white;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  text-shadow: 0px 2px 10px rgba(0,0,0,.2);
}

a {
  color: #033667;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 20px;
  /*font-family: "thirsty-script", sans-serif;*/
  font-weight: 700;
  font-style: normal;
}

.wrapper {
  margin-top: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2em;
}

.header-section {
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 50px;
}

.ingress {
  color: white;
  font-size: 20px;
  max-width: 700px;
  text-align: center;
  margin-top: 0px;
}

.footer {
  margin-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding-bottom: 20px;
}

.footer.is-done {
  margin-top: 7vh;
}

.footer-links a {
  color: white;
  margin: 0px 10px;
}

.footer p {
  max-width: 700px;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
}

.response-wrapper {
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  background: white;
  padding: 20px;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
}

.response-footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.response-bottom {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #C4EAED;
    padding: 20px;
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.response-bottom h5 {
  font-size: 25px;
  margin: 0px;
  font-family: "georgiapro", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.logo {
  margin-top: 20px;
}

input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  padding: 15px 22px;
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  font-size: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
}

.link-button, button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background-color: #033667;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  padding: 17px 28px;
  transition: background-color 0.3s ease-in;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
  transition: 0.3s ease-in-out;
}

.small-button {
  font-size: 15px;
  border-radius: 6px !important;
  margin: 0px 10px;
}

.response {
  background: #f8f8f8;
  padding: 10px 20px;
  width: 100%;
  max-width: 620px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  line-height: 26px;
  animation: curtain 1s ease-in-out;
  /* box-shadow: 0px 3px 15px rgba(0,0,0,.3); */
}

.prompt-form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 700px;
}

.prompt-form input {
  width: 40%;
}

.warning {
  font-size: 13px;
  color: white;
  margin-top: 0px;
  font-style: italic;
}

img.note {
  position: absolute;
}

.note1 {
  width: 100px; height: auto; bottom: 140px; right: 50px;
}

.note2 {
  width: 100px; height: auto; bottom: 240px; right: 0px;
}

.note3 {
  width: 100px; height: auto; bottom: 200px; right: 100px;
}

.chat-bubble {
  background-color: #f3e4b3;
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #d88a48;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

.notification-slide {
  position: fixed;
  border-radius: 6px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
  bottom: -50px; /* Initially set outside the view */
  left: 0;
  right: 0;
  background-color: #033667;
  color: white;
  text-align: center;
  padding: 15px;
  font-size: 18px;
  transition: all 0.3s!important;
  animation: slideUp 0.3s ease-in-out;
  z-index: 1000; /* Ensure it appears above other content */
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
}

.copied {
  bottom: 20px;
}

.topSearches {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.topSearches ol {
  padding-left: 1em;
}

.topSearches ol li {
  cursor: pointer;
  text-decoration: underline;
  padding-bottom: 5px;
  font-size: 20px;
}


.link-button:hover, button:hover {
  /* Zoom effect */
  transform: scale(1.05);
  animation: boxShadowPulse 1s infinite;
  /* Pulse effect */
}


@keyframes boxShadowPulse {
  0% {
    box-shadow: 0 0 0px rgba(3, 54, 103, 0.8);
  }
  50% {
    box-shadow: 0 0 15px rgba(3, 54, 103, 0.5);
  }
  100% {
    box-shadow: 0 0 30px rgba(3, 54, 103, 0);
  }
}


@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #d88a48;
  }
  28% {
    transform: translateY(-7px);
    background-color: #d2996a;
  }
  44% {
    transform: translateY(0px);
    background-color: #d8b396;
  }
}

@keyframes curtain {
  0% {
    max-height: 0px;
    color: #f8f8f8;
  }
  50% {
    max-height: 1000px;
    color: #f8f8f8;
  }
  100% {
    max-height: 1000px;
    color: #000;
  }
}

@keyframes slideUp {
  0% {
    bottom: -50px;
    opacity: 0;
  }
  100% {
    bottom: 20px;
    opacity: 1;
  }
}

@media (max-width: 600px) {
  body {
    width: 100%;
    margin: 0px;
  }

  ol,
  ul {
    padding-left: 25px;
  }

  .response-bottom {
    padding: 20px 10px !important;
    width: calc(90% - 20px);
    max-width: calc(90% - 20px) !important;
}

.response-bottom h5 {
  font-size: 20px;
}

.topSearches {
  flex-direction: column;
}

.topSearches div {
    width: 90%;
    text-align: left;
}

.topSearches ol li {
  font-size: 17px;
}

  .wrapper {
    width: 100% !important;
    max-width: 90% !important;
    margin: 0 auto;
    margin-bottom: 2em;
    margin-top: 12vh !important;
  }

  p.warning {
    text-align: center !important;
  }

  .footer {
    width: 90% !important;
    margin: 0 auto;
    margin-top: 20vh;
  }

  .header-section {
    padding-bottom: 30px;
  }

  .response-wrapper {
    padding: 10px !important;
    width: calc(90% - 20px);
    max-width: calc(90% - 20px) !important;
  }


  .note {
    width: 55px!important;
  }

  .note1 {
    bottom: 80px;
    right: 10px;
}

.note2 {
    bottom: 120px;
    right:30px;
}

.note3 {
    bottom: 130px;
    right: 0px;
}

  .roger-hero {
    width: 100%!important;
  }

  .ingress {
    font-size: 15px;
  }

  .prompt-form {
    width: 100%;
  }

  .prompt-form input {
    width: 50%;
    font-size: 17px;
  }

  .prompt-form button {
    font-size: 17px !important;
    width: 50%;
  }

  .response {
    padding: 10px !important;
    width: 100% !important;
    max-width: 95% !important;
    font-size: 14px !important;
  }

  .response-footer {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .footer-links a {
    padding: 5px 0px;
  }

  .response-footer .small-button {
    width: 50% !important;
    padding: 15px 10px !important;
  }

  h1.roger-header {
    font-size: 50px;
    bottom: -3px;
  }
}
</style>
